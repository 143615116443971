<template>
	<div>
		<form @submit.prevent="search" @input="debouncedSearch" class="mt-5 mb-5">
			<div class="md:grid md:grid-cols-1 md:gap-6">
				<div class="mt-5 md:mt-0 md:col-span-2">
					<div>
						<div class="sm:rounded-md">
							<div class="sm:rounded-md px-4 py-5 bg-gray-50 space-y-6 sm:p-6">
								<fieldset>
									<div class="col-span-3 sm:col-span-2">
										<div class="flex mt-1 rounded-md">
											<input
												v-model="filter.search"
												type="text"
												class="flex-1 block w-full border-gray-300 rounded-none focus:ring-blue-500 focus:border-blue-500 rounded-l-md sm:text-sm"
												:placeholder="$i18n('search.title')"
											/>
											<div class="flex flex-1 rounded-md shadow-sm">
												<span
													class="inline-flex items-center px-3 text-sm text-gray-500 border border-l-0 border-r-0 border-gray-300 rounded-none bg-gray-50"
												>
													{{ $i18n("misc.sorting") }}
												</span>

												<select
													v-model="filter.order_by"
													class="inline-flex flex-1 px-3 py-2 bg-white border border-gray-300 w-lg shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
												>
													<option
														v-for="value in options.order_by"
														:value="value"
														:key="value"
														>{{ $i18n(`filter.${value}`) }}</option
													>
												</select>
											</div>
											<select
												v-model="filter.order_type"
												class="inline-flex flex-1 px-3 py-2 bg-white border border-l-0 border-gray-300 shadow-sm rounded-r focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											>
												<option
													v-for="value in options.order_type"
													:value="value"
													:key="value"
													>{{ $i18n(`filter.${value}`) }}</option
												>
											</select>
										</div>
									</div>
								</fieldset>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import debounce from "@/utils/debounce";
export default {
	name: "DataFilter",
	props: {
		default: {
			type: Object,
			default: () => ({
				order_by: "updated_at",
				order_type: "DESC",
				search: undefined,
			}),
		},
		defaultOptions: {
			type: Object,
			default: () => ({
				order_by: ["id", "name", "created_at", "updated_at"],
				order_type: ["ASC", "DESC"],
			}),
		},
	},
	data(vm) {
		const {
			default: { order_by, order_type, search },
			defaultOptions,
		} = vm;
		return {
			sources: [],
			filter: {
				order_by,
				order_type,
				search,
			},
			options: {
				order_by: defaultOptions.order_by,
				order_type: defaultOptions.order_type,
			},
			isFilterUpdated: false,
		};
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.isFilterUpdated = true;
			},
		},
	},
	methods: {
		async search() {
			try {
				if (this.isFilterUpdated) {
					const {
						$router,
						$route,
						$route: { query },
						$parent,
					} = this;
					await $router.push({
						...$route,
						query: { ...query, ...this.filter },
					});

					this.isFilterUpdated = false;
					$parent.$emit("Filter");
				}
			} catch (error) {
				console.debug(error);
			}
		},
		syncFilter() {
			try {
				const {
					$route: { query },
				} = this;
				if (Object.keys(query).length) {
					this.filter = query;
				}
			} catch (error) {
				console.debug(error);
			}
		},
	},
	created() {
		try {
			this.syncFilter();
			this.debouncedSearch = debounce(this.search, 500);
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
