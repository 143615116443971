export default function(func, wait, immediate) {
	let timeout;
	return function() {
		let context = this,
			args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		}, wait);
		if (immediate && !timeout) func.apply(context, args);
	};
}
